const SUPPORTED_SOCIAL_IDPS = [
  'facebook',
  'google',
  'linkedin',
  'microsoft',
  'apple',
  'github',
  'gitlab',
  'yahoo',
  'line',
  'paypal',
  'paypal_sandbox',
  'salesforce',
  'amazon',
  'yahoojp',
  'discord',
  'adobe',
  'orcid',
  'spotify',
  'xero',
  'quickbooks',
];
export default {
  SUPPORTED_SOCIAL_IDPS,
};
