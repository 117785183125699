export default {
  GMT: 'UTC',
  WET: 'WET',
  CET: 'CET',
  MET: 'CET',
  ECT: 'CET',
  EET: 'EET',
  MIT: 'Pacific/Apia',
  HST: 'Pacific/Honolulu',
  AST: 'America/Anchorage',
  PST: 'America/Los_Angeles',
  MST: 'America/Denver',
  PNT: 'America/Phoenix',
  CST: 'America/Chicago',
  EST: 'America/New_York',
  IET: 'America/Indiana/Indianapolis',
  PRT: 'America/Puerto_Rico',
  CNT: 'America/St_Johns',
  AGT: 'America/Argentina/Buenos_Aires',
  BET: 'America/Sao_Paulo',
  ART: 'Africa/Cairo',
  CAT: 'Africa/Harare',
  EAT: 'Africa/Addis_Ababa',
  NET: 'Asia/Yerevan',
  PLT: 'Asia/Karachi',
  IST: 'Asia/Kolkata',
  BST: 'Asia/Dhaka',
  VST: 'Asia/Ho_Chi_Minh',
  CTT: 'Asia/Shanghai',
  JST: 'Asia/Tokyo',
  ACT: 'Australia/Darwin',
  AET: 'Australia/Sydney',
  SST: 'Pacific/Guadalcanal',
  NST: 'Pacific/Auckland',
};
